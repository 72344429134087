var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "ma-0 pa-0",
      attrs: { fluid: "" },
    },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers.filter(({ show }) => show),
          height: _vm.tableHeight,
          items: _vm.groupedItems,
          "item-class": _vm.rowHighlight,
          "items-per-page": _vm.pageSize,
          dense: "",
          "fixed-header": "",
          "hide-default-footer": "",
          "loading-text": "Loading... Please Wait",
          "no-data-text": "No Ad Items to Display",
          "no-results-text": "No Matches Found",
          "item-key": "item_id",
          "show-select": "",
          expanded: _vm.expanded,
          "group-by": "combinedKey",
          "sort-by": "last_90_days_shipped_qty",
          "sort-desc": "",
        },
        on: {
          "update:items": function ($event) {
            _vm.groupedItems = $event
          },
          "update:expanded": function ($event) {
            _vm.expanded = $event
          },
          "click:row": function ($event) {
            _vm.highlighted = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "toolbar" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "center", dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "3", xl: "2" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "input",
                                  "hide-details": "",
                                  label: "Store",
                                  items: _vm.stores,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "return-object": "",
                                },
                                model: {
                                  value: _vm.store,
                                  callback: function ($$v) {
                                    _vm.store = $$v
                                  },
                                  expression: "store",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pr-0",
                              attrs: { lg: "3", xl: "2" },
                            },
                            [
                              _c("v-autocomplete", {
                                class: _vm.iProEdgeAccess ? "rounded-r-0" : "",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "input",
                                  "hide-details": "",
                                  label: "Ad",
                                  items: _vm.ads,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "return-object": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-list-item-subtitle",
                                              { staticClass: "black--text" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(" Due Date: ")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getOrderDeadlines(
                                                        data.item.ad_date
                                                      ).date
                                                    ) +
                                                    " "
                                                ),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold",
                                                  },
                                                  [_vm._v(" Status: ")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    class: `${
                                                      _vm.getOrderDeadlines(
                                                        data.item.ad_date
                                                      ).color
                                                    }--text`,
                                                    attrs: {
                                                      size: "10",
                                                      left: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getOrderDeadlines(
                                                            data.item.ad_date
                                                          ).open
                                                            ? "OPEN"
                                                            : "CLOSED"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.ad,
                                  callback: function ($$v) {
                                    _vm.ad = $$v
                                  },
                                  expression: "ad",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.iProEdgeAccess
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pl-0",
                                  staticStyle: { "max-width": "150px" },
                                  attrs: { lg: "2", xl: "2" },
                                },
                                [
                                  _c("v-autocomplete", {
                                    class: _vm.iProEdgeAccess
                                      ? "rounded-l-0"
                                      : "",
                                    attrs: {
                                      readonly:
                                        _vm.orderingClosed ||
                                        !_vm.iProEdgeManagementAccess,
                                      outlined: "",
                                      dense: "",
                                      "background-color": "input",
                                      "hide-details": "",
                                      label: "Status",
                                      items: ["Draft", "Submitted"],
                                      value: _vm.ad.order_status
                                        ? _vm.ad.order_status.status
                                        : "Draft",
                                    },
                                    on: { change: _vm.updateOrderStatus },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.iProEdgeAccess && !_vm.readOnlyView
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "amber darken-3 white--text ml-2 text-capitalize",
                                                  attrs: {
                                                    disabled:
                                                      _vm.selected.length < 1,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      _vm.barChart = true
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-chart-timeline-variant"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    962186182
                                  ),
                                },
                                [_c("span", [_vm._v("Suggested Order")])]
                              )
                            : _vm._e(),
                          _vm.iProEdgeAccess && !_vm.readOnlyView
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "pl-3",
                                  attrs: { lg: "2", xl: "2" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-2 text-capitalize",
                                      attrs: {
                                        color: "error",
                                        disabled: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.resetOrdersModal = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Reset Total to Original Suggestion "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2 text-capitalize",
                              on: {
                                click: function ($event) {
                                  _vm.exportModal = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-file-excel-outline"),
                              ]),
                              _vm._v(" Exports "),
                            ],
                            1
                          ),
                          _c("SearchFilters", {
                            attrs: {
                              ad: _vm.ad,
                              departments: _vm.departments,
                              pages: _vm.pages,
                              featureTypes: _vm.featureTypes,
                              featureTexts: _vm.featureTexts,
                              isShipper: _vm.isShipper,
                              quantityFilter: _vm.quantityFilter,
                            },
                            model: {
                              value: _vm.searchParams,
                              callback: function ($$v) {
                                _vm.searchParams = $$v
                              },
                              expression: "searchParams",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: `header.data-table-select`,
              fn: function ({ on, props }) {
                return [
                  _vm.iProEdgeAccess && !_vm.readOnlyView
                    ? _c(
                        "v-badge",
                        {
                          attrs: {
                            color: "amber darken-3",
                            value: _vm.selected.length > 0,
                            content: _vm.selected.length,
                            inline: "",
                          },
                        },
                        [
                          _c(
                            "v-simple-checkbox",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: { ripple: false, dark: "" },
                                  on: { click: _vm.toggleAllItems },
                                },
                                "v-simple-checkbox",
                                props,
                                false
                              ),
                              on
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: `group.header`,
              fn: function ({ group, headers, items, isOpen, toggle }) {
                return [
                  _c(
                    "td",
                    {
                      staticClass: "blue-grey lighten-2 white--text",
                      staticStyle: {
                        height: "40px",
                        "border-top": "1px solid grey !important",
                        cursor: "pointer",
                      },
                      attrs: { colspan: headers.length },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: group,
                              attrs: {
                                dark: "",
                                small: "",
                                icon: "",
                                "data-open": isOpen,
                              },
                              on: { click: toggle },
                            },
                            [
                              _c("v-icon", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      isOpen
                                        ? "mdi-chevron-up"
                                        : "mdi-chevron-down"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "ml-1 font-weight-medium" },
                            [_vm._v(" " + _vm._s(group) + " ")]
                          ),
                          _vm.iProEdgeAccess && !_vm.readOnlyView
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "white--text ml-2 font-weight-medium text-capitalize",
                                  attrs: { href: "#", text: "" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toggleItemSelection(items)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.itemsInGroup(items)
                                          ? "Clear All"
                                          : "Select All"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("v-divider", {
                            staticClass: "mx-4 mb-1 white",
                            attrs: { inset: "", vertical: "" },
                          }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { color: "main", top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-badge",
                                          {
                                            attrs: {
                                              value: items.some(
                                                ({ feature_note }) =>
                                                  feature_note
                                              ),
                                              right: "",
                                              color: "amber darken-2",
                                              overlap: "",
                                              dot: "",
                                              "offset-x": "15",
                                              "offset-y": "15",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openNote(
                                                          items,
                                                          group
                                                        )
                                                      },
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-note-text-outline"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(" Notes ")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.data-table-select`,
              fn: function ({ item, isSelected }) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "text-center",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _vm.iProEdgeAccess &&
                      _vm.isOpenStock(item) &&
                      !_vm.readOnlyView
                        ? _c("v-simple-checkbox", {
                            staticClass: "mb-1",
                            attrs: {
                              ripple: false,
                              value: _vm.selected.some(
                                (selectedItem) =>
                                  selectedItem &&
                                  selectedItem.item_id == item.item_id
                              ),
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addToSelectedItems(isSelected, item)
                              },
                            },
                          })
                        : _vm._e(),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _vm.iProEdgeAccess &&
                                    _vm.isOpenStock(item) &&
                                    !_vm.readOnlyView
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mr-2 mb-1",
                                              attrs: {
                                                tabindex: "-1",
                                                small: "",
                                                icon: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.getProductLinkedGroup(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("mdi-link")])],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Linked Group")])]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "expanded-item",
              fn: function ({ headers, item }) {
                return [
                  _c(
                    "td",
                    {
                      staticClass: "px-0",
                      staticStyle: { cursor: "pointer" },
                      attrs: { colspan: headers.length },
                      on: {
                        click: function ($event) {
                          _vm.highlighted = item
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "px-4",
                          style: {
                            borderBottom:
                              item.item_id === _vm.highlighted.item_id
                                ? "2px solid primary"
                                : null,
                            paddingTop:
                              item.item_id === _vm.highlighted.item_id
                                ? "3px"
                                : null,
                          },
                        },
                        [
                          _vm.items.length && _vm.showDeliveryDates
                            ? _c(
                                "v-row",
                                {
                                  staticClass: "mt-1",
                                  attrs: { dense: "", align: "center" },
                                },
                                [
                                  _vm._l(item.item_orders, function (order) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: order.item_id,
                                        staticClass: "pt-1 mb-3",
                                        staticStyle: { height: "50px" },
                                        attrs: { cols: "1" },
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "deliveryDay mt-2",
                                          attrs: {
                                            readonly: _vm.readOnlyView,
                                            "hide-details": "",
                                            outlined: "",
                                            "background-color":
                                              order.processed == "true"
                                                ? "grey lighten-3"
                                                : "white",
                                            dense: "",
                                            type: "number",
                                            color:
                                              order.processed == "true"
                                                ? "grey darken-1"
                                                : "",
                                            label: _vm.formatLabel(
                                              order.delivery_date,
                                              order
                                            ),
                                            value:
                                              order.quantity > 0
                                                ? order.quantity
                                                : null,
                                            min: "1",
                                          },
                                          on: {
                                            focus: function ($event) {
                                              _vm.activeInput = order
                                            },
                                            change: function ($event) {
                                              return _vm.selectMethod(
                                                item,
                                                order,
                                                $event
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            order.processed ==
                                                                            "true"
                                                                              ? "green"
                                                                              : "red",
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            order.processed ==
                                                                              "true"
                                                                              ? "mdi-checkbox-marked-circle-outline"
                                                                              : ""
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                order.processed ==
                                                                  "true"
                                                                  ? "Order Processed"
                                                                  : "Ordering Closed"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mr-1",
                                      staticStyle: { "max-width": "150px" },
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "align-center",
                                          staticStyle: { display: "flex" },
                                        },
                                        [
                                          _c(
                                            "v-badge",
                                            {
                                              staticClass:
                                                "font-weight-bold mr-3",
                                              attrs: {
                                                overlap: "",
                                                color: "amber darken-3",
                                                value:
                                                  _vm.getTotalOrders(
                                                    item.item_orders
                                                  ) > 0,
                                                content: _vm.getTotalOrders(
                                                  item.item_orders
                                                ),
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(" mdi-cart-outline "),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        !_vm.orderingClosed
                                                          ? _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    disabled:
                                                                      _vm.getTotalOrders(
                                                                        item.item_orders
                                                                      ) < 1,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.clearItemOrders(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "red",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-trash-can"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Clear Cart")])]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        !_vm.orderingClosed
                                                          ? _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    disabled:
                                                                      !_vm.getOrderChanged(
                                                                        item.item_orders
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.clearItemOrders(
                                                                          item,
                                                                          true
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "warning",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-refresh-circle"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Reset Item to Original Suggestion"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          item.upc == "0000000000000"
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                  attrs: {
                                                                    icon: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showComponentsModal(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-shipping-pallet"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Components"),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  Object.entries(item.amap_dates).length > 0 ||
                                  Object.entries(item.tpr_dates).length > 0
                                    ? _c("v-divider", {
                                        staticStyle: { "margin-bottom": "2px" },
                                        attrs: { vertical: "" },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "2" } },
                                    [
                                      Object.entries(item.amap_dates).length > 0
                                        ? [
                                            _c("b", { staticClass: "mx-4" }, [
                                              _vm._v("AMAP Dates:"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${_vm.formatDate(
                                                    item.amap_dates.start
                                                  )} - ${_vm.formatDate(
                                                    item.amap_dates.end
                                                  )}`
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : _vm._e(),
                                      item.amap_dates && item.tpr_dates
                                        ? _c("br")
                                        : _vm._e(),
                                      Object.entries(item.tpr_dates).length > 0
                                        ? [
                                            _c("b", { staticClass: "ml-4" }, [
                                              _vm._v("TPR Dates:"),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${_vm.formatDate(
                                                    item.tpr_dates.start
                                                  )} - ${_vm.formatDate(
                                                    item.tpr_dates.end
                                                  )}`
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.item_status`,
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "noWrap font-weight-bold",
                      class:
                        item.item_status === "A"
                          ? "success--text"
                          : "error--text",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.item_status === "A" ? "Active" : "Discontinued"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.last_90_days_shipped_qty`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      _vm._s(
                        item.last_90_days_shipped_qty
                          ? item.last_90_days_shipped_qty
                          : "---"
                      )
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.net_case_cost`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(" $" + _vm._s(item.net_case_cost) + " "),
                  ]),
                ]
              },
            },
            {
              key: `item.ad_retail`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(item.ad_retail_count) +
                        "/" +
                        _vm._s(item.ad_retail) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "toolbar" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "end", align: "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disablePrevious,
                              },
                              on: { click: _vm.prevPage },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                icon: "",
                                large: "",
                                disabled: _vm.disableNext,
                              },
                              on: { click: _vm.nextPage },
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.dialog },
          on: {
            "update:value": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    { staticClass: "red--text mr-3", attrs: { size: "35" } },
                    [_vm._v("mdi-alert-octagon")]
                  ),
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v("Unable to Proceed"),
                  ]),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(" No order delivery days are assigned to store. "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize font-weight-medium",
                      attrs: { text: "", to: { path: "/retail-manager" } },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AlertDialog", {
        attrs: { value: _vm.showDialog, options: _vm.options },
        on: {
          "update:value": function ($event) {
            _vm.showDialog = $event
          },
          confirm: _vm.confirmEvent,
        },
      }),
      _c("ChartModal", {
        attrs: {
          value: _vm.barChart,
          item: _vm.highlighted,
          store: _vm.store,
          selected: _vm.selected,
          ad: _vm.ad,
          readonly: _vm.readOnlyView,
        },
        on: {
          "update:value": function ($event) {
            _vm.barChart = $event
          },
          confirmUpdate: _vm.confirmUpdate,
        },
      }),
      _c("ComponentItems", {
        key: _vm.componentKey,
        attrs: { value: _vm.showComponents, shipper: _vm.shipper },
        on: {
          "update:value": function ($event) {
            _vm.showComponents = $event
          },
        },
      }),
      _c("Loader", {
        attrs: { value: _vm.showLoader, message: _vm.loaderMessage },
        on: {
          "update:value": function ($event) {
            _vm.showLoader = $event
          },
        },
      }),
      _c("FeatureNote", {
        attrs: { value: _vm.showNote, item: _vm.selectedItem },
        on: {
          "update:value": function ($event) {
            _vm.showNote = $event
          },
          save: _vm.saveNote,
        },
      }),
      _c("Exports", {
        attrs: {
          value: _vm.exportModal,
          ad: _vm.ad,
          stores: _vm.stores,
          userEmail: _vm.userEmail,
        },
        on: {
          "update:value": function ($event) {
            _vm.exportModal = $event
          },
        },
      }),
      _vm.resetOrdersModal
        ? _c("ResetOrderGrid", {
            attrs: {
              value: _vm.resetOrdersModal,
              ad_id: _vm.ad.id,
              store_id: _vm.store.id,
            },
            on: {
              "update:value": function ($event) {
                _vm.resetOrdersModal = $event
              },
              "refresh-grid": _vm.getOrderGridData,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }